/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'

/**
 * This middleware must be called on each admin page
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated
 *
 * This middleware check if the user is an admin:
 * - If the user is not an admin, the user is redirected to the previous page.
 */
export default defineNuxtRouteMiddleware(async () => {
  const logger = useService('logger')
  logger.info('[ADMIN] Middleware fired')

  const { getUser } = useUserState()
  if (!getUser().isAdmin()) {
    logger.info('[ADMIN] User is not admin, redirecting')

    return navigateTo('/')
  }
})
